<template>
  <section>
    <v-container fluid>
      <!-- Header -->
      <v-sheet class="overflow-hidden mt-n2 transparent pa-0">
        <v-toolbar color="transparent" class="pa-0" flat>
          <v-col cols="auto" class="py-1 font-weight-bold">
            {{ $t('follow booking') }}
         </v-col>
          <v-spacer/>
          <v-btn fab text small color="grey darken-2 mx-1" @click="next">
            <v-icon small>mdi-chevron-right</v-icon>
          </v-btn>
          <v-toolbar-title class="px-2">
            {{ activeDay | dateAr }}
          </v-toolbar-title>
          <v-btn fab text small color="grey darken-2 mx-1" @click="prev">
            <v-icon small>
              mdi-chevron-left
            </v-icon>
          </v-btn>
          <v-btn outlined class="mx-4" color="grey darken-2" @click="setToday">
            {{ $t('today') }}
          </v-btn>
        </v-toolbar>
      </v-sheet>

      <!-- End Of Header -->

      <v-row class="fill-height" v-if="!pageData.isLoading">
        <v-col>

          <v-sheet :height="$store.state.Settings.windowSize.y - 150" class="position-relative" :key="calendarKey">
            <template v-if="is_day_off == false">
              <div class="first_time ">
                {{ new Date(`${activeDay} ${times[0].time}`).toLocaleTimeString($vuetify.rtl == true ? 'ar' : 'en-UK', {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      })
                }}

              </div>
              <v-calendar ref="calendar" v-model="focus" color="primary" type="category"
                :interval-minutes="interval_minutes" :locale="$vuetify.rtl == true ? 'ar' : 'en-US'"
                :interval-count="times.length" :first-time="times[0].time" :now="today" :start="activeDay"
                category-show-all :categories="barbers" :events="events" :event-color="getEventColor"
                @change="fetchEvents" @click:event="showEvent">

                <template v-slot:category="{ category }">
                  <v-sheet color="gray11" class="pa-3 rounded-0 position-relative " style="z-index: 300000;">
                    <v-row justify="center" align="center">
                      <v-col cols="auto" class="subtitle-2 py-1 font-weight-bold gray3--text"
                        v-lazy-container="{ selector: 'img' }">
                        <img :height="40" :width="40"
                          :data-src="$store.state.endpointURL + $global.FilterArrayOfObjectElement(all_barbers, 'id', +category, 'image')"
                          class="d-block  mi-start-auto rounded-circle" :alt="category" />
                      </v-col>
                      <v-col cols="auto" class="subtitle-2 py-1 font-weight-bold gray3--text">


                        {{ $global.FilterArrayOfObjectElement(all_barbers, 'id', +category, 'name') | capitalize }}
                      </v-col>
                    </v-row>

                  </v-sheet>

                </template>
                <template v-slot:interval="{ category, time }">
                  <v-col cols="12" lg="10" class="ma-auto py-0 d-block">
                    <v-hover v-slot="{ hover }">
                      <v-btn depressed small block :height="32"
                        class="d-block rounded-xl mt-2 blue1--text  ma-auto position-relative" :class="{ '': !hover }"
                        style="z-index: 20;" color="blue12" @click="addEvent(category, `${activeDay}`, `${time}:00`)"
                        :disabled="!isAvailableFromNow(activeDay, time)"
                        v-if="((checkIfAvail(+category, `${time}:00`)) && activeDay >= today)">
                        <v-icon left>mdi-plus</v-icon>
                        {{ $t('add') }} {{ $t('from time') }}
                        {{ new Date(`${activeDay} ${time}`).toLocaleTimeString($vuetify.rtl == true ? 'ar' : 'en-UK', {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      })
                        }}
                      </v-btn>
                      <div v-else-if="(activeDay < today)" style="width : 100% ;height: 35px;"
                        class=" text-center mt-2 gray1--text d-flex align-center justify-center ma-auto position-relative"
                        :class="{ ' gray9--text': !hover, 'gray9': hover }">
                        {{ '-' }}
                      </div>
                      <div v-else style="width : 100% ;height: 35px;"
                        class=" text-center mt-2 gray1--text d-flex align-center justify-center ma-auto position-relative"
                        :class="{ ' gray9--text': !hover, 'gray9': hover }">
                        {{ $t('out of the shift') }}
                      </div>

                    </v-hover>
                  </v-col>
                </template>
                <template v-slot:event="{ event }">
                  <v-col cols="12" lg="12" class="position-relative">
                    <v-row class="text-truncate ma-auto" justify="center" align="center">
                      <v-col cols="6" class="d-flex pa-0 justify-end">
                        <div class="font-weight-bold text-subtitle-1 px-2">
                          {{ event.booking.barber_customer_name }} - {{ event.booking.barber_customer_mobile }}
                          <!-- {{ event.booking.barber_customer_name }} <br> {{ event.booking.barber_customer_mobile }} -->
                        </div>

                      </v-col>
                      <v-col cols="6" class="gray12--text pa-0 ">
                        <div class="d-flex align-start flex-column">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <div v-bind="attrs" v-on="on">
                                <v-icon color="white">mdi-account-wrench</v-icon> {{ $t('services') }}
                              </div>
                            </template>
                            <span class="d-flex " v-for="(service, index) in event.name.split(',').map(i => i)"
                              :key="index">
                              <v-icon color="gray11" size="7">mdi-circle</v-icon>
                              <div class="mx-1">{{ service }}</div>
                            </span>
                          </v-tooltip>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                </template>

              </v-calendar>
            </template>

            <template v-if="(is_day_off == true)">
              <v-col cols="12" class="py-16">
                <v-alert class="my-16 rounded-xl text-center" color="info" outlined>
                  <v-icon color="blue1" size="60">mdi-information</v-icon>
                  <div class="text-h5 mt-5"> {{ $t('branch is off this day') | capitalize }}</div>
                </v-alert>
              </v-col>
            </template>


          </v-sheet>
        </v-col>
      </v-row>


      <!-- end Of Calender -->

      <div class="my-16" v-if="pageData.isLoading">
        <AnimatedLoading :height="100" :isLoading="pageData.isLoading" />
      </div>

      <ShowEventRequestDialog :event="selectedEvent" :dialog="eventDialog" :booking_date="activeDay"
        :backValueMethod="(status) => { eventDialog = false; selectedEvent = Object; status == true ? getData() : eventDialog = false }" />
    </v-container>
  </section>
</template>

<script>
import AnimatedLoading from "@/components/AnimatedLoading.vue";
import ShowEventRequestDialog from "./ShowEventRequestDialog";
export default {
  name: "BarberBookingEntity",

  data: () => ({
    pageData: {
      screen_code: "10-004",
      url: null,
      entityName: null,
      controlRoute: "barber/barber_booking-control",
      main: null,
      category: null,
      isLoading: true,
      isTrashed: 1,
      rowsNumber: 15,
      tableHeader: [],
      search: "",
      rows: [],
      options: {},
      page: 1,
      pagination: {},
      queryParam: '?'
    },
    calendarKey: 1,
    today: null,
    activeDay: null,
    focus: '',
    barbers: [],
    events: [],
    colors: ['primary', 'accent', 'success', 'gray2'],
    booking: [],
    times: [],
    all_barbers: [],
    eventDialog: false,
    is_day_off: false,
    selectedEvent: Object,
    // 
    dragEvent: null,
    interval_minutes: 30,
    dragStart: null,
    createEvent: null,
    createStart: null,
    extendOriginal: null,
  }),
  components: {
    ShowEventRequestDialog,
    AnimatedLoading
  },
  computed: {
    isAvailableFromNow() {
      // User can't add resarvation before 20 min from now
      return (date, time) => {
        const time_now = /*new Date(new Date().getTime() + 60 * 60 * 1000)*/ new Date();
        var startTime = time_now;
        var endTime = new Date(`${date} ${time}`);
        var difference = endTime.getTime() - startTime.getTime();
        var resultInMinutes = Math.round(difference / 60000);
        return (resultInMinutes >= 20)
      }
    },
    checkIfAvail() {
      return (barber_id ,time) => {
        if (barber_id) {
          let findTimes = [];
          let barberShifts = this.all_barbers.find(barber => barber.id == barber_id) || [];
          barberShifts?.barber_work_times?.forEach(shift => {
            if (barber_id && time && shift.beginning_work && shift.end_work) {
            let beginning_work = shift.beginning_work;
            let end_work = shift.end_work;
            var currentDate = new Date(`${this.activeDay} ${time}`)
            var startDate = new Date(currentDate.getTime());
            startDate.setHours(beginning_work.split(":")[0]);
            startDate.setMinutes(beginning_work.split(":")[1]);
            startDate.setSeconds(beginning_work.split(":")[2]);

            var endDate = new Date(currentDate.getTime());
            endDate.setHours(end_work.split(":")[0]);
            endDate.setMinutes(end_work.split(":")[1]);
            endDate.setSeconds(end_work.split(":")[2]);
            findTimes.push(startDate <= currentDate && endDate >= currentDate)
            console.log('is available', startDate <= currentDate && endDate >= currentDate);
            }else{
              findTimes.push(false)
            }
          });
          return findTimes.some(time => time === true);
        } else {
          return false
        }
      }
      // return (barber_id, time) => {
      //   const beginning_work = this.$global.FilterArrayOfObjectElement(this.all_barbers, 'id', +barber_id, 'beginning_work');
      //   const end_work = this.$global.FilterArrayOfObjectElement(this.all_barbers, 'id', +barber_id, 'end_work');
      //   if (barber_id && time && beginning_work && end_work) {
      //     // console.log('data', beginning_work, end_work, time);

      //     var currentDate = new Date(`${this.activeDay} ${time}`)

      //     var startDate = new Date(currentDate.getTime());
      //     startDate.setHours(beginning_work.split(":")[0]);
      //     startDate.setMinutes(beginning_work.split(":")[1]);
      //     startDate.setSeconds(beginning_work.split(":")[2]);

      //     var endDate = new Date(currentDate.getTime());
      //     endDate.setHours(end_work.split(":")[0]);
      //     endDate.setMinutes(end_work.split(":")[1]);
      //     endDate.setSeconds(end_work.split(":")[2]);

      //     // console.log('calc', startDate, endDate, currentDate);
      //     const valid = startDate <= currentDate && endDate >= currentDate
      //     return valid
      //   } else {
      //     return false
      //   }
      // }
    },
  },
  watch: {
    $route() {
      this.pageMainData();
      this.getData();
    }
  },
  mounted() {
    this.pageMainData();
    this.getData();
  },
  methods: {
    pageMainData() {
      this.today = this.$global.GetCurrentDate();
      this.activeDay = this.$route.params.active_date || this.$global.GetCurrentDate();
    },
    getData() {
      this.is_day_off = false;
      this.calendarKey = this.calendarKey + 1

        this.pageData.isLoading = true;
        this.$api
          .GET_METHOD(`barber_booking?active_date=${this.$route.params.active_date || this.activeDay}`)
          .then((response) => {
            this.pageData.isLoading = false;
            if (response.check) {
              this.barbers = []
              this.booking = []
              this.is_day_off = response.data.is_day_off
              this.all_barbers = response.data.barbers;
              this.times = response.data.times;
              this.interval_minutes = response.data.interval_minutes || 30;
              response.data.barbers.forEach(barber => {
                this.barbers.push(String(barber.id));
                barber.beginning_work = barber.barber_work_times[0].beginning_work;
                barber.end_work = barber.barber_work_times[0].end_work;
                barber.barber_work_times = barber.barber_work_times || [];
              });
              Object.keys(response.data.items).forEach(barber => {

                response.data.items[barber].forEach(request => {
                  request.barber = barber;
                  request.start = this.$global.FilterArrayOfObjectElement(response.data.times, 'id', request.booking_start_time, 'time')
                  request.end = this.$global.FilterArrayOfObjectElement(response.data.times, 'id', request.booking_end_time, 'time')
                  this.booking.push(request)
                });

              });
              this.$refs.calendar.checkChange();
            }
          });
      
    },
    getEventColor(event) {
      return event.color
    },
    setToday() {
      this.$router.push(`/barber/barber_booking/${this.today}`)
    },
    prev() {
      this.$router.push(`/barber/barber_booking/${this.$global.SubtractFromDate(this.activeDay, 1)}`)
    },
    next() {
      this.$router.push(`/barber/barber_booking/${this.$global.AddToDate(this.activeDay, 1)}`)
    },
    fetchEvents() {
      const events = []
      this.booking.forEach(event => {
        // console.log(event);
        var start = new Date(`${this.activeDay} ${event.start}`);
        var start_utc = new Date(start.getTime() + start.getTimezoneOffset());
        var end = new Date(`${this.activeDay} ${event.end}`);
        if(!event.end && event.start  == '23:30:00'){ // booking end in next day
          end = new Date(`${this.$global.AddToDate(this.activeDay, 1)} 00:00:00`)
        }
        var end_utc = new Date(end.getTime() + end.getTimezoneOffset());
        events.push({
          name: event.services_names,
          start: start_utc,
          end: end_utc,
          color: event.has_invoice == 1 ? 'success' : 'primary',
          timed: true,
          category: event.barber,
          booking: event
        })

      });
      this.events = events
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a
    },
    extendBottom(event) {
      this.createEvent = event
      this.createStart = event.start
      this.extendOriginal = event.end
    },
    startDrag({ event, timed }) {
      if (event && timed) {
        this.dragEvent = event
        this.dragTime = null
        this.extendOriginal = null
      }
    },
    endDrag() {
      this.dragTime = null
      this.dragEvent = null
      this.createEvent = null
      this.createStart = null
      this.extendOriginal = null
    },
    cancelDrag() {
      if (this.createEvent) {
        if (this.extendOriginal) {
          this.createEvent.end = this.extendOriginal
        } else {
          const i = this.events.indexOf(this.createEvent)
          if (i !== -1) {
            this.events.splice(i, 1)
          }
        }
      }

      this.createEvent = null
      this.createStart = null
      this.dragTime = null
      this.dragEvent = null
    },
    showEvent({ event }) {
      console.log(event);
      this.eventDialog = true;
      this.selectedEvent = event
    },
    addEvent(id, date, time) {

      let time_id = this.$global.FilterArrayOfObjectElement(this.times, 'time', time, 'id')
      if (time_id && id && date && this.isAvailableFromNow(date, time)) {
        this.$router.push(`/barber/barber_booking-control?barber_booking_date=${date}&barber_id=${id}&start_time=${time_id}`)
      }
    },

  },

};
</script>



<style scoped lang="scss">
.first_time {
  position: absolute;
  z-index: 56;
  font-size: 12px;
  font-weight: 700;
  color: #424242;
  font-size: 10px;
  padding-right: 4px;
  top: 52px;

}

.v-application--is-rtl {
  .first_time {
    right: 10px;
  }
}

.v-application--is-ltr {
  .first_time {
    left: 10px;
  }
}

.v-event-draggable {
  padding-left: 6px;
}

.v-event-timed {
  user-select: none;
  -webkit-user-select: none;
}

.v-event-drag-bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 4px;
  height: 4px;
  cursor: ns-resize;

  &::after {
    display: none;
    position: absolute;
    left: 50%;
    height: 4px;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    width: 16px;
    margin-left: -8px;
    opacity: 0.8;
    content: '';
  }

  &:hover::after {
    display: block;
  }
}
</style>