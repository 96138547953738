import Vue from "vue";
import store from "../store";
var Tafgeet = require("tafgeetjs");
Vue.filter("capitalize", function (value) {
  if (!value) return "";
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
});
Vue.filter("encrypt", function (value) {
  return Vue.prototype.$CryptoJS.enc.Base64.stringify(
    Vue.prototype.$CryptoJS.enc.Utf8.parse(value)
  );
});
Vue.filter("decrypt", function (value) {
  return Vue.prototype.$CryptoJS.enc.Base64.parse(value).toString(
    Vue.prototype.$CryptoJS.enc.Utf8
  );
});
Vue.filter("double", function (NUMBER) {
  return Number(NUMBER).toFixed(2);
});
Vue.filter("float", function (NUMBER) {
  let number = Number(NUMBER).toFixed(store.getters.decimal_digits);
  return number.toString().replace(/\B(?=(\d{3})+(\.))/g, ",");
});
Vue.filter("noFixed", function (NUMBER) {
  let number = Number(NUMBER);
  return number.toString().replace(/\B(?=(\d{3})+(\.))/g, ",");
});
Vue.filter("abs", function (NUMBER) {
  let number = NUMBER ? NUMBER.toFixed(store.getters.decimal_digits) : 0
  if(number > 0)
  return  number.toString().replace(/\B(?=(\d{3})+(\.))/g, ",");
  else   if(number < 0) 
  return '(' + number.toString().replace(/\B(?=(\d{3})+(\.))/g, ",").replaceAll('-','+') + ')';
  else
  return '-'
});
Vue.filter("nonZero", function (NUMBER) {
  let number = NUMBER ? NUMBER.toFixed(store.getters.decimal_digits) : 0
  if(number > 0)
  return  number.toString().replace(/\B(?=(\d{3})+(\.))/g, ",");
  else   if(number < 0) 
  return '(' + Math.abs(number).toString().replace(/\B(?=(\d{3})+(\.))/g, ",") + ')';
  else
  return '-'
});
Vue.filter("tafqeet", function (NUMBER) {
  var stringText = new Tafgeet(NUMBER, store.getters.currency?.currency_code || 'SAR').parse();
  return stringText;
});
Vue.filter("time", function (value) {
  // console.log(Vue.prototype.vuetify.rtl , 'Vue.prototype.vuetify.rtl');
  const date = new Date().toLocaleDateString();
  const time =  new Date(`${date} ${value}:00`).toLocaleTimeString("ar", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  }) 
  return value ? time  : '--:--:--'
});
Vue.filter("date", function (value) {
  return new Date(value).toLocaleDateString("ar", {
    weekday: "short",
    year: "numeric",
    month: "numeric",
    day: "numeric",
  });
});
Vue.filter("dateAr", function (value) {
  return new Date(value).toLocaleDateString("ar", {
    weekday: "short",
    year: "numeric",
    month: "numeric",
    day: "numeric",
  });
});
Vue.filter("dateEn", function (value) {
  return new Date(value).toLocaleDateString("en-UK", {
    weekday: "short",
    year: "numeric",
    month: "numeric",
    day: "numeric",
  });
});

export default {};
