<template>
  <v-card class="rounded-b-lg transparent rounded-0 elevation-0  justify-center pa-0"
    :height="this.$store.state.Settings.windowSize.y - 255" style="overflow-y: auto;
        ">
    <v-col cols="12" sm="12" class="pa-0 transparent  d-flex align-center">
      <v-simple-table fixed-header v-if="getInvoiceStore.invoice.length > 0" class="transparent rounded-lg" dense
        style="overflow-x: hidden !important ;width:100%" :height="this.$store.state.Settings.windowSize.y - 255">
        <!-- Header -->
        <thead>
          <tr>
            <th v-if="extraCol">{{ $t('#') }}</th>
            <th>{{ $t('item') }}</th>
            <th>{{ $t('item count') }}</th>
            <th>{{ $t('price') }}</th>
            <th>{{ $t('tax') }}</th>
            <th>{{ $t('total') }}</th>
            <th>{{ $t('delete') }}</th>
          </tr>
        </thead>
        <!-- Body -->
        <tbody>
          <tr v-for="(row, index) in getInvoiceStore.invoice" :key="index" style=" animation: fadeIn 0.4s !important;">
            <td class="caption" v-if="extraCol">
              <span class="gray7--text d-block">{{ row.item_number ? `#${row.item_number}` : '' }}</span>
              <span class="gray7--text  d-block">{{ row.location ? `#${row.location}` : '' }}</span>
            </td>
            <td class="caption">{{ row.item_name || '-' }}
              <v-chip color="success" small v-if="row.applied_in_coupon">{{ $t('coupon') }}</v-chip>
            </td>
            <td class="d-flex align-center caption justify-space-between" style="height: 100%;">
              <v-btn elevation="0" color="transparent" fab :disabled="(stock_watch ? row.quantity == row.stock : false)"
                @click="increment(row.id)" x-small>
                <v-icon color="success">mdi-plus</v-icon>
              </v-btn>
              {{ row.quantity || 0 }}
              <v-btn elevation="0" color="transparent" fab @click="decrement(row.id)" x-small>
                <v-icon color="error">mdi-minus-thick</v-icon>
              </v-btn>
            </td>
            <td class="caption">{{ row.total_price_without_vat || 0 | float }} <span v-if="Number(row.total_discount)"
                class="error--text">({{ row.total_discount | float }})</span></td>
            <td>
              {{ row.tax_percentage }}%
            </td>
            <td class="caption">{{ row.total_with_vat || 0 | float }}</td>
            <td class="caption">

              <v-tooltip color="error" bottom open-delay="500" open-on-hover>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" elevation="0" color="transparent" fab @click="deleteItem(index)"
                    x-small>
                    <img src="@/assets/img/pos_svg/clear.svg" alt="Qawaem" height="20">
                  </v-btn>
                </template>
                <span class="caption">{{ $t('delete item') | capitalize }}</span>
              </v-tooltip>



            </td>
          </tr>
        </tbody>
        <!-- Footer -->
        <tfoot style="position: sticky; bottom : 0">
          <tr v-if="getInvoiceStore.invoice.length != 0">
            <th :colspan="extraCol ? 2 : 1" style="border-top:1px solid #eee !important; sticky_header">{{ $t('total')
              }}
            </th>
            <th style="border-top:1px solid #eee !important; sticky_header">
              {{ totalPieces }}
            </th>

            <th style="border-top:1px solid #eee !important; sticky_header" class="text-center">
              {{ totalPrice | float }} <span v-if="getInvoiceStore.total_discount" class="error--text">({{
                getInvoiceStore.total_discount | float
              }})</span>
            </th>
            <th style="border-top:1px solid #eee !important; sticky_header" class="text-center">
              {{ getInvoiceStore.total_vat_after_discount || totalVat | float }}
            </th>
            <th colspan="1" style="border-top:1px solid #eee !important; sticky_header" class="text-start">
              <!-- {{ totalPriceWithVat | float }} {{ $t('ryal') }} -->
              {{ totalPriceWithVatComputed | float }} {{ $t('ryal') }}
            </th>
            <th colspan="1" style="border-top:1px solid #eee !important;">


              <v-menu offset-y :close-on-content-click="false" open-on-hover close-delay="300">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" elevation="0" color="transparent" fab x-small>
                    <v-icon color="secondary">mdi-message-outline</v-icon>
                  </v-btn>
                </template>
                <v-list class="px-2">
                  <TextAreaInput :value="description" @input="setDescription($event)" label="description"
                    :required="false" :isLoading="false" />
                </v-list>
              </v-menu>




              <v-tooltip color="error" bottom open-delay="500" open-on-hover>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" elevation="0" color="transparent" fab @click="clearInvoice" x-small>
                    <img src="@/assets/img/pos_svg/clearAll.svg" alt="Qawaem" height="20">
                  </v-btn>
                </template>
                <span class="caption">{{ $t('delete all') | capitalize }}</span>
              </v-tooltip>
            </th>
          </tr>
        </tfoot>
      </v-simple-table>


      <v-col cols="12" v-if="getInvoiceStore.invoice.length == 0">
        <img src="@/assets/img/pos_svg/no_data.svg" class="d-block mb-2 ma-auto" :height="100" alt="" />
        <div class="text-center gray5--text">{{ $t('no item added') | capitalize }}</div>
      </v-col>
    </v-col>
   </v-card>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import TextAreaInput from "./inputs/TextAreaInput.vue";
// import GenericInput from "./GenericInput.vue";
const { mapState, mapActions, mapGetters } = createNamespacedHelpers("InvoiceStore");
export default {
  name: "InvoiceData",
  data: () => ({
    height: 0,

  }),
  props: {
    stock_watch: { default: 0 },
    totalPriceWithVatMethod: { default: null },
  },
  mounted() {

  },
  watch: {
    totalPriceWithVatComputed() {
      this.totalPriceWithVatMethod(this.totalPriceWithVatComputed)
    }
  },
  computed: {
    ...mapState(["totalPrice", "invoice", "description", "totalPriceWithVat", "totalVat", "total_vat_after_discount", "totalPieces", "discountPercent", "taxes", "discount"]),
    ...mapGetters(["getInvoiceStore"]),
    extraCol() {
      return this.getInvoiceStore.invoice.some(item => item.item_number || item.location)
    },
    totalPriceWithVatComputed() {
      return this.getInvoiceStore.invoice.reduce((acc, item) => {
        return acc + (item.total_with_vat || 0);
      }, 0);
    }
    // availInCoupon() {
    //   return (row) => {
    //     let items = this.discount.coupon.services.split(',').map(i => +i) || []
    //     return items.includes(row.item_id)
    //   }
    // },
    // calculateCoupon() {
    //   let itemsInCoupon = this.discount.coupon.services.split(',').map(i => +i) || []
    //   let appliedToInvoiceItems = []
    //   this.getInvoiceStore.invoice.forEach(item => {
    //     if (itemsInCoupon.includes(item.item_id)) {
    //       if (this.discount.coupon_id) {
    //         let total_price_without_vat = (item.price_without_vat - this.discount.coupon.discount) * item.quantity;
    //         let total_vat = total_price_without_vat * (item.tax_percent / 100)
    //         let total_price_with_vat = total_price_without_vat + total_vat

    //         appliedToInvoiceItems.push({
    //           item_name: item.item_name,
    //           total_price_without_vat: total_price_without_vat,
    //           total_vat: total_vat,
    //           total_price_with_vat: total_price_with_vat,
    //         })
    //       }
    //     }
    //   });

    //   return {
    //     total_price_without_vat: this.$global.ARRAY_REDUCE(appliedToInvoiceItems, "total_price_without_vat"),
    //     total_vat: this.$global.ARRAY_REDUCE(appliedToInvoiceItems, "total_vat"),
    //     total_price_with_vat: this.$global.ARRAY_REDUCE(appliedToInvoiceItems, "total_price_with_vat"),
    //   };
    // }
  },
  methods: {
    ...mapActions(["clearInvoice", "deleteItemAction", "increment", "decrement", "setInvoice", "setDescription", "setTax",]),
    deleteItem(index) {
      this.deleteItemAction(index).finally(() => { });
    },
    setRowTax(id, tax_percent_id) {
      this.setTax(
        {
          id: id,
          tax_percent_id: tax_percent_id,
        }
      );
    }
  },
  components: {
    TextAreaInput
  }
};
</script>



<style scoped lang="scss"></style>
