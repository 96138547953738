<template>
  <section id="bgAuth" class="light">
    <v-container fluid>
      <v-col cols="12" md="10" lg="8" xl="6" class="ma-auto">
        <div class="justify-center d-flex align-end">
          <v-col cols="12" class="pa-0">
            <v-card elevation-0 class="shadow d-flex align-center mt-10 rounded-0" min-height="570">
              <v-col cols="9" class="px-5">
                <v-btn v-on:click="changeLanguage" small fab class="bg-white langBtn d-block ma-auto" elevation="0">
                  <img src="@/assets/img/svg/USA.svg" height="30" v-if="$vuetify.rtl == true" alt="Qawaem-POS" />
                  <img src="@/assets/img/svg/KSA.svg" height="30" v-if="$vuetify.rtl == false" alt="Qawaem-POS" />
                </v-btn>
                <v-col cols="12" class="d-flex justify-center align-center">
                  <img src="@/assets/logo.png" height="80" class="mb-3" alt="Qawaem">
                </v-col>
                <v-form ref="form" class="px-5" v-model="valid" lazy-validation>
                  <!-- company number -->
                  <GenericInput type="text" name="company_number" icon="mdi-shield-check" :keydownEnter="login"
                    :solo="true" :value="form.company_number" @input="form.company_number = $event"
                    label="company number" :required="true" :isLoading="false" :cols="[12, 12, 12]"></GenericInput>
                  <!-- username -->
                  <GenericInput type="text" name="username" icon="mdi-account" :keydownEnter="login" :solo="true"
                    :value="form.username" @input="form.username = $event" label="username" :required="true"
                    :isLoading="false" :cols="[12, 12, 12]"></GenericInput>
                  <!-- password -->
                  <GenericInput type="password" name="password" :keydownEnter="login" :solo="true"
                    :value="form.password" @input="form.password = $event" label="password" :required="true"
                    :isLoading="false" :cols="[12, 12, 12]"></GenericInput>
                  <v-col cols="12" class="d-flex justify-space-between align-center">
                    <v-btn depressed :height="40" text width="150" to="/forget-password" color="primary">
                      {{ $t('forget password?') }}
                    </v-btn>
                    <v-btn depressed :height="40" width="150" :disabled="!(valid)" :loading="loading" @click="login"
                      color="primary">{{ $t('login') }}
                    </v-btn>
                  </v-col>


                </v-form>
              </v-col>

            </v-card>
          </v-col>
          <v-col cols="12" md="auto" class="d-none d-md-block pa-0" id="imgAuth">
            <v-card elevation-0 class=" d-flex align-center justify-center rounded-0" width="400" height="570">
              <img src="@/assets/img/pos_svg/cashierBG.png" alt="Qawaem">
              <div class="pa-5">
                <div class="white--text text-h5 my-5">{{ $t('welcome in qawaem') | capitalize }}</div>
                <div class="white--text text-h6 my-5">{{ $t('join our community of more than 10,000 subscribers') |
                  capitalize
                  }}</div>
              </div>

            </v-card>
          </v-col>
        </div>
      </v-col>
    </v-container>
    <ShiftSummery />
  </section>
</template>


<style scoped lang="scss">
#bgAuth {
  position: relative;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 200px;

  .langBtn {
    position: absolute;
    top: 30px;
    right: unset !important;
    left: 50px !important;
  }

  #imgAuth {
    position: relative;
    top: -55px;
    left: -200px;
    overflow: hidden;

    img {
      position: absolute;
      top: 0
    }

    -webkit-box-shadow: 0px 0px 99px -3px rgb(136 136 136);
    -moz-box-shadow: 0px 0px 99px -3px rgba(136, 136, 136, 1);
    box-shadow: 0px 0px 99px -3px rgb(136 136 136) !important;

    .text-h6,
    .text-h5 {
      position: relative;
      z-index: 10;
      text-align: center;
    }
  }
}

.v-application--is-rtl {
  #bgAuth {
    padding-left: 0;
    padding-right: 200px;

    .langBtn {
      left: unset !important;
      right: 50px !important;
    }
  }

  #imgAuth {
    left: unset;
    right: -200px;
  }
}
</style>



<script>
import GenericInput from '@/components/GenericInput.vue';
import ShiftSummery from '@/components/ui/ShiftSummery.vue';
export default {
  name: "LoginPage",
  computed: {
  },
  watch: {
    'form.company_number'() {
      this.$store.commit('UPDATE_CLIENT', this.form.company_number || null)
    },
  },
  updated() {
    this.$getToken().then((token) => {
      console.log('fcm token', token);
      this.form.fcm_token = token
    });
  },
  mounted() {
    this.$api.LOGOUT(false);
  },
  components: {
    GenericInput,
    ShiftSummery
  },
  data: () => ({
    valid: false,
    loading: false,
    form: {
      email: null,
      password: null,
      company_number: null,
      fcm_token: null,
    }
  }),
  methods: {
    changeLanguage() {
      this.$store.dispatch("Settings/changeLanguage");
    },
    login() {
      this.loading = true;
      this.$refs.form.validate()
      if (this.valid == true && this.$store.state.clientID) {
        this.$api.LOGIN_AUTH(this.form).then((response) => {
          this.loading = false;
          if (response?.check == true) {
            this.$global.sendNotification('Qawaem - POS', `${this.$i18n.t('welcome')} ${response.data.full_name}`)
            if (response.data.shift == null) {
              this.$router.push('/start-sift');
            } else {
              this.$router.push('/');

            }
            localStorage.setItem("clientID", this.$store.state.clientID)
          } else {
            this.$store.commit('UPDATE_CLIENT', this.form.company_number || null)
          }
        })
      } else {
        this.loading = false;
      }
    }
  },
};
</script>
