<template>
  <v-card class="shadow-none" :height="noticeInvoice == false ? this.$store.state.Settings.windowSize.y - 260 : 'auto'"
    :class="{ 'scrollItems': !noticeInvoice }">
    <v-col cols="12" class="pa-0 mt-1 px-2" sm="12">
      <v-row align="stretch">

        <!-- Loop -->
        <v-col cols="6" xl="3" :lg="$store.state.sidebarIsOpen ? 4 : 3" md="4" class="itemCol"
          :class="{ 'disabled': stock_watch && item.stock <= 0 }"
          @click="selectItem(item, stock_watch && item.stock <= 0)" v-for="(item, index) in items" :key="index">
          <!-- <v-card @click="selectItem(item)" class="pa-2 rounded-sm shadow-none">
            <v-avatar size="85" class="rounded-0 d-block mx-auto" v-lazy-container="{ selector: 'img'  }">
              <img :data-src="$store.state.endpointURL + item.image" :alt="item.name" />
            </v-avatar>
            <div class="caption font-weight-bold success--text text-end">{{item.price_with_vat || 0 | float}}</div>
            <div class="caption font-weight-bold shadow py-2 text-center">{{item.name || '-' | capitalize}}</div>
          </v-card> -->

          <button v-ripple class="item_button mt-5">
            <div class="caption item_price   text-center">{{ item.price_with_vat || 0 | float }}</div>
            <div class="caption font-weight-bold pb-1 text-center">
              <small>
                {{ item.name || '-' | capitalize }}
                <span class="gray7--text d-block">{{ item.item_number ? `#${item.item_number}` : '' }}</span>
                <span class="gray7--text d-block">{{ item.location ? `#${item.location}` : '' }}</span>
                <span class="d-block" :class="item.stock <= 0 ? 'error--text' : 'gray7--text'" v-if="stock_watch">{{
                  item.stock > 0 ? `#${$t('balance')} :
                                  ${item.stock}` : `# ${$t('balance not enough')}` }}</span>
              </small>
            </div>
          </button>

        </v-col>
        <!-- End Of loop -->

        <v-col v-if="items.length == 0" class="text-center">
          <span>{{ $t("items") | capitalize }} {{ $t("not available here") }}</span>
        </v-col>


      </v-row>
    </v-col>
  </v-card>
</template>


<script>
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("InvoiceStore");
export default {
  name: "CategoryItems",
  props: {
    backValueMethod: { type: Function },
    items: { default: [] },
    activeCategory: { default: 1 },
    disabledCategory: { default: false },
    noticeInvoice: { default: false },
    backItemMethod: { type: Function },
    stock_watch: { default: 0 },
  },
  data: () => ({
    active: null
  }),
  computed: {
    ...mapState(["invoice", "totalPrice"]),
  },
  watch: {
    active() {
      this.backValueMethod(this.active)
    },
    activeCategory() {
      this.active = this.activeCategory
    }
  },
  mounted() {

    this.active = this.activeCategory
  },
  beforeDestroy: function () {

  },
  methods: {
    ...mapActions(["addInvoiceAction", "clearInvoice"]),
    selectItem(item, disabled) {
      if (!disabled) {
        if (!this.stock_watch || item.stock <= 0) {
          this.setItem(item);
          return;
        }

        const found = this.invoice.some(invoice => invoice.item_id === item.id);
        const getItemInInvoice = this.invoice.find(invoice => invoice.item_id === item.id) || {};

        if (!found || (found && getItemInInvoice.quantity < item.stock)) {
          this.setItem(item);
        } else {
          this.$swal.fire({
            title: this.$t('balance not enough'),
            icon: 'error',
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },
    setItem(item) {
      this.active = item.id;
      if (this.noticeInvoice) {
        item.quantity = 1
        this.backItemMethod(item);
      } else {
        this.addInvoiceAction(item).finally(() => { });
      }
    }
  },
};
</script>



<style scoped lang="scss">
.itemCol {
  padding: 5px;
  padding-top: 4px;
  padding-bottom: 4px;
  min-width: 110px;
  display: grid;
  position: relative;

  .item_button {
    min-height: 45px;
    padding-top: 10px;

    &:disabled {
      opacity: 0.7;
    }
  }

  &:is(.disabled) {
    &::after {
      position: absolute;
      width: 100%;
      height: 100%;
      content: '';
    }
  }

  &:hover:not(.disabled) {
    .item_button {
      background: #45ade2;
      color: #fff !important;
      border: 1px solid #45ade2 !important;

      span {
        color: #fff !important;
        opacity: 0.69;
      }
    }

    .item_price {
      background: #FFF !important;
      border: 1px solid #45ade2 !important;
      color: #354c72 !important;

    }
  }
}

.item_price {
  width: min-content;
  background: #45ade2;
  border: 1px solid #45ade2 !important;
  transition: 0.3s;
  margin: auto;
  padding: 0.2rem 0.6rem;
  border-radius: 25px;
  color: #fff !important;
  position: absolute;
  top: -1px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.item_button {
  // border: 1px solid var(--primary);.
  transition: 0.3s;
  border: 1px solid #ccc !important;
  border-radius: 5px;
  text-align: center;
  width: 100%;
  position: relative;
}

.scrollItems {
  overflow-y: auto;
  overflow-x: hidden;
  background-color: transparent;
}
</style>
