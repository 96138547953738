<template>
  <v-card class="rounded-sm elevation-0 justify-center pa-0" :max-height="height >= 300 ? height : 300" style="
                                                                                overflow-y: auto;
                                                                                background-color:  transparent ;
                                                                            ">
    <v-col cols="12" sm="12" class="pa-0">
      <v-simple-table id="itemsDataTable" style="overflow-x: hidden !important ;">
        <template v-slot:default>
          <thead>
            <tr>
              <th></th>
              <th>{{ $t('item') }}</th>
              <th>{{ $t('items count') }}</th>
              <th>{{ $t('price') }}</th>
              <th>{{ $t('vat') }}</th>
              <th>{{ $t('price with vat') }}</th>
            </tr>
          </thead>
          <tbody>

            <tr v-for="(row, index) in computedItems" :key="index" style=" animation: fadeIn 0.4s !important;">

              <td>
                <div class="d-flex justify=space-between align-center">
                  <v-checkbox color="success" class="d-block my-auto" hide-details @change="(e) => {
                    row.refund_item = !row.refund_item; checkboxMethod(selectedRow)
                  }" v-model="selectedRow" :value="row" :indeterminate="(row.notice_type == 'new_item')" />
                  <v-chip small class="mx-2" v-if="row.notice_type == 'new_item'" color="success">{{ $t('new')
                    }}</v-chip>
                </div>
              </td>

              <td style="width:150px">
                <div class="d-flex align-center">
                  {{ row.item_name | capitalize }}
                </div>
              </td>

              <td v-if="!row.refund_item">{{ row.current_quantity }}</td>
              <!-- Quantity -->
              <td v-if="row.refund_item" class="d-flex ma-auto align-center justify-center">

                <v-btn elevation="0" color="transparent" fab @click="increment(row, index)"
                  :class="((row.current_quantity <= (row.new_quantity || 0)) && credit) ? 'opacity-0' : 'opacity-1'"
                  :disabled="((row.current_quantity <= (row.new_quantity || 0)) && credit)" x-small class="mx-1">
                  <v-icon color="success">mdi-plus</v-icon>
                </v-btn>

                {{ row.current_quantity }}

                <div width="70px" class="ms-1">
                  <NumberInput :value="row.qtyInput" @input="setQuantity(row, index, $event)" placeholder="quantity"
                    :solo="true" :disableArrow="true" />
                </div>

                <!-- <span v-if="row.current_quantity !== row.new_quantity" class="mx-1">
                  ({{ (row.difference_quantity || row.current_quantity) }})
                </span> -->

                <v-btn elevation="0" color="transparent" fab @click="decrement(row, index)" x-small
                  :class="((row.current_quantity >= (row.new_quantity || 0)) && debit) ? 'opacity-0' : 'opacity-1'"
                  :disabled="((row.current_quantity >= (row.new_quantity || 0)) && debit)" class="mx-1">
                  <v-icon color="error">mdi-minus-thick</v-icon>
                </v-btn>

              </td>


              <td style="width:150px">
                <div v-if="row.refund_item && canEditPrice">
                  <FloatInput :value="+row.new_total_without_vat.toFixed(2)" @input="row.new_total_without_vat = $event"
                    label="" :required="false" :isLoading="false" />
                </div>
                <div v-else>
                  {{ row.current_total_without_vat | float }}
                  <span v-if="+row.current_total_without_vat.toFixed(2) !== +row.new_total_without_vat.toFixed(2)">
                    ({{ row.difference_total_without_vat || row.current_total_without_vat | float }})
                  </span>
                </div>
              </td>
              <td style="width:150px">
                {{ row.current_total_vat | float }}
                <span v-if="+row.current_total_vat.toFixed(2) !== +row.new_total_vat.toFixed(2) && row.refund_item">
                  ({{ row.difference_total_vat || row.current_total_vat | float }})
                </span>
              </td>
              <td style="width:150px">
                {{ row.current_total_with_vat | float }}
                <span
                  v-if="+row.current_total_with_vat.toFixed(2) !== +row.new_total_with_vat.toFixed(2) && row.refund_item">
                  ({{ row.difference_total_with_vat || row.current_total_with_vat | float }})
                </span>
              </td>
            </tr>

            <tr v-if="items.length != 0">
              <th colspan="2" class="font-weight-bold">{{ $t('total') }}</th>
              <th colspan="1">
                {{ totalsCalculations.current_quantity }}
                <span v-if="totalsCalculations.difference_quantity">
                  ({{ totalsCalculations.difference_quantity }})
                </span>
              </th>
              <th colspan="1">
                {{ totalsCalculations.current_total_without_vat | float }}
                <span v-if="totalsCalculations.difference_total_without_vat">
                  ({{ totalsCalculations.difference_total_without_vat | float }})
                </span>
              </th>
              <th colspan="1">
                {{ totalsCalculations.current_total_vat | float }}
                <span v-if="totalsCalculations.difference_total_vat">
                  ({{ totalsCalculations.difference_total_vat | float }})
                </span>
              </th>
              <th colspan="1">
                {{ totalsCalculations.current_total_with_vat | float }}
                <span v-if="totalsCalculations.difference_total_with_vat">
                  ({{ totalsCalculations.difference_total_with_vat | float }})
                </span>
              </th>
            </tr>
            <tr v-if="items.length == 0" class="white">
              <th colspan="9" class="py-3" style="border : 0">
                <v-alert class="d-block ma-auto" dense outlined text type="info">
                  {{ $t('this invoice is been refund') | capitalize }}
                </v-alert>
              </th>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-col>
  </v-card>
</template>

<script>
import FloatInput from './inputs/FloatInput.vue';
import NumberInput from './inputs/NumberInput.vue';


export default {
  name: "NoticeDataTable",
  data: () => ({
    height: 0,
    selectedRow: []
  }),
  props: {
    items: { default: [] },
    discount: {
      default: {
        type: 1,
        value: 0,
        discount_amount: 0,
        percent_value: 0,
      }
    },
    credit: { default: false },
    debit: { default: false },
    canEditPrice: { default: false },
    checkboxMethod: { type: Function },
  },
  mounted() {
    this.height = window.innerHeight - 370;
    this.computedItems = this.items;
  },
  watch: {
    items() {
      this.computedItems = this.items;
    },
  },
  computed: {
    computedItems() {
      if (this.items) {
        this.items.forEach(item => {
          if (item.new_quantity) {
            // For item
            item.new_price = item.new_total_without_vat / item.new_quantity;
            item.new_vat = item.new_price * (item.tax_percent / 100);
            item.new_with_vat = item.new_price + item.new_vat;
            // all item qty
            item.new_total_vat = item.new_total_without_vat * (item.tax_percent / 100);
            item.new_total_with_vat = item.new_total_without_vat + item.new_total_vat;
            // difference
            item.difference_quantity = Math.abs(+item.current_quantity - +item.new_quantity)
            item.difference_total_without_vat = Math.abs(item.current_total_without_vat - item.new_total_without_vat);
            item.difference_total_vat = Math.abs(item.current_total_vat - item.new_total_vat);
            item.difference_total_with_vat = Math.abs(item.current_total_with_vat - item.new_total_with_vat);
          } else {
            item.new_price = 0;
            item.new_vat = 0;
            item.new_with_vat = 0;
            // all item qty
            item.new_total_without_vat = 0;
            item.new_total_vat = 0;
            item.new_total_with_vat = 0;
            // difference
            item.difference_quantity = 0;
            item.difference_total_without_vat = 0;
            item.difference_total_vat = 0;
            item.difference_total_with_vat = 0;
          }
        });
        return this.items;
      }
      else {
        return [];
      }
    },
    totalsCalculations() {
      if (this.items.length > 0) {
        // current
        const current_quantity = this.items.reduce((oldValue, newValue) => {
          return +oldValue + +newValue.current_quantity || 0;
        }, 0);
        const current_total_without_vat = this.items.reduce((oldValue, newValue) => {
          return +oldValue + +newValue.current_total_without_vat || 0;
        }, 0);
        const current_total_vat = this.items.reduce((oldValue, newValue) => {
          return +oldValue + +newValue.current_total_vat || 0;
        }, 0);
        const current_total_with_vat = this.items.reduce((oldValue, newValue) => {
          return +oldValue + +newValue.current_total_with_vat || 0;
        }, 0);
        // new
        const new_quantity = this.items.reduce((oldValue, newValue) => {
          return +oldValue + +newValue.new_quantity || 0;
        }, 0);
        const new_total_without_vat = this.items.reduce((oldValue, newValue) => {
          return +oldValue + +newValue.new_total_without_vat || 0;
        }, 0);
        const new_total_vat = this.items.reduce((oldValue, newValue) => {
          return +oldValue + +newValue.new_total_vat || 0;
        }, 0);
        const new_total_with_vat = this.items.reduce((oldValue, newValue) => {
          return +oldValue + +newValue.new_total_with_vat || 0;
        }, 0);

        // difference
        const difference_quantity = +Math.abs(current_quantity - new_quantity).toFixed(2);
        const difference_total_without_vat = +Math.abs(current_total_without_vat - new_total_without_vat).toFixed(2);
        const difference_total_vat = +Math.abs(current_total_vat - new_total_vat).toFixed(2);
        const difference_total_with_vat = +Math.abs(current_total_with_vat - new_total_with_vat).toFixed(2);

        return {
          // current
          current_quantity: current_quantity,
          current_total_without_vat: current_total_without_vat,
          current_total_vat: current_total_vat,
          current_total_with_vat: current_total_with_vat,
          // new
          new_quantity: new_quantity,
          new_total_without_vat: new_total_without_vat,
          new_total_vat: new_total_vat,
          new_total_with_vat: new_total_with_vat,
          // difference
          difference_quantity: difference_quantity,
          difference_total_without_vat: difference_total_without_vat,
          difference_total_vat: difference_total_vat,
          difference_total_with_vat: difference_total_with_vat,
        };
      }
      else {
        return {
          // current
          current_quantity: 0,
          current_total_without_vat: 0,
          current_total_vat: 0,
          current_total_with_vat: 0,
          // new
          new_quantity: 0,
          new_total_without_vat: 0,
          new_total_vat: 0,
          new_total_with_vat: 0,
          // difference
          difference_quantity: 0,
          difference_total_without_vat: 0,
          difference_total_vat: 0,
          difference_total_with_vat: 0,
        };
      }
    },
  },
  methods: {
    increment(row, index) {
      let item = this.items[index];
      item.new_price = item.new_price == 0 ? item.item_price_after_discount : item.new_price
      item.new_quantity = item.new_quantity + 1;
      item.qtyInput = item.current_quantity - item.new_quantity
      // if you want to auto calculate price when increment quantity apply next line
      item.new_total_without_vat = item.new_price * item.new_quantity;
      this.items[index] = item;
    },
    decrement(row, index) {
      let item = this.items[index];
      if (this.items[index].new_quantity >= 1) {
        item.new_quantity = item.new_quantity - 1;
        // if you want to auto calculate price when decrement quantity apply next line
        item.new_total_without_vat = item.new_price * item.new_quantity
        this.items[index] = item;
      }
      item.qtyInput = item.current_quantity - item.new_quantity
    },
    setQuantity(row, index, value) {
      let item = this.items[index];
      item.qtyInput = value;
      if (item.current_quantity === value) {
        item.new_quantity = 0;
      } else {
        if (value >= 0 && item.current_quantity >= value) {
          item.new_quantity = item.current_quantity - value;
          item.qtyInput = Math.min(value, item.current_quantity);
        } else {
          item.new_quantity = 0;
          item.qtyInput = item.current_quantity;
        }
      }
      item.new_price = item.new_price === 0 ? item.item_price_after_discount : item.new_price;
      item.new_total_without_vat = item.new_price * item.new_quantity;
      this.items[index] = item;
    }
  },
  components: { FloatInput, NumberInput }
};
</script>



<style scoped lang="scss">
#itemsDataTable {
  thead {
    tr {
      background-color: #FFFFFF !important;

      &:hover {
        background-color: #FFFFFF !important;
      }
    }
  }

  tr {
    &:hover {
      background: transparent !important;
    }

    &:nth-of-type(even) {
      background-color: #fff;

      &:hover {
        background-color: #fff !important;
      }
    }

    &:nth-of-type(odd) {
      background-color: #FFFFFF;

      &:hover {
        background-color: #FFFFFF !important;
      }
    }

    &:last-of-type {
      background-color: #e8e8ee;

      &:hover {
        background-color: #e8e8ee !important;
      }
    }
  }

  td,
  th {
    border-bottom: 1px solid #e0e0e0 !important;
    // border-bottom: 0 !important;
    text-align: center !important;
  }

  td:last-of-type,
  th:last-of-type {
    //border-bottom: 1px solid #e0e0e0  !important;
  }
}
</style>
